import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import ApiConfig from './ApiConfig';
import Navbar from './Navbar';
import DetailsContent from './DetailsModal';
import { json } from 'react-router-dom';

const columns = [
  { id: 'leadDate', label: 'Lead Date' },
  { id: 'customerName', label: 'Customer Name' },
  { id: 'email', label: 'Email' },
  { id: 'phone', label: 'Phone' },
  { id: 'reportTitle', label: 'Report Title' },
  { id: 'nameOfCompany', label: 'Company Name' },
  { id: 'designation', label: 'Designation' },
  { id: 'country', label: 'Country' },
  { id: 'reportId', label: 'Report ID' },
  { id: 'reportURL', label: 'Report URL' },
  { id: 'enquiryStatus', label: 'Status' },
];

const getUserRole = () => {
  return localStorage.getItem('role') || 'Guest';
};

const userRole = getUserRole();
const visibleColumns = userRole === 'Admin'
  ? columns
  : columns.filter(column => ['leadDate', 'reportTitle', 'country', 'reportURL'].includes(column.id));

// Specify the column names you want to show in the main table
const columnsToShowInTable = ['leadDate', 'customerName', 'email', 'country','phone', 'reportId', 'reportTitle','enquiryStatus'];

const columnsToShowInDetails = ['leadDate', 'customerName', 'reportTitle', 'reportId', 'reportURL', 'country','enquiryStatus'];

const StyledPaper = styled(Paper)({
  padding: (theme) => theme.spacing(2),
  marginBottom: (theme) => theme.spacing(2),
});

const StyledTableContainer = styled(TableContainer)({
  maxHeight: '500px',
  overflow: 'auto',
});

const StyledTableHead = styled(TableHead)({
  position: 'sticky',
  top: 0,
  fontWeight: 'bold',
  backgroundColor: 'whitesmoke',
  fontFamily: 'Helvetica Neue, sans-serif',
});

const StyledSearchBox = styled(TextField)({
  marginTop: (theme) => theme.spacing(2),
  marginBottom: (theme) => theme.spacing(2),
});

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiConfig.enquiry}`);
        console.log(response.data);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data.filter((item) =>
    columns.some((column) =>
      item[column.id] && item[column.id].toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowDetails = async (row) => {
    try {
      const response = await axios.get(`${ApiConfig.enquiry}?EnquiryId=${row.id}`);
      setSelectedRow(response.data);
      setModalOpen(true);
    } catch (error) {
      console.error('Error fetching details:', error);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <Navbar />
      <StyledPaper>
        <Box display="flex" flexDirection="column" alignItems="stretch" padding={1}>
          <StyledSearchBox
            label="Search"
            variant="outlined"
            margin="normal"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <StyledTableContainer>
            <Table>
              <StyledTableHead>
                <TableRow>
                  {columnsToShowInTable.map((columnId) => (
                    <TableCell key={columnId}>
                      {columns.find((column) => column.id === columnId)?.label}
                    </TableCell>
                  ))}
                  <TableCell>Details</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow key={row.id}>
                    {columnsToShowInTable.map((columnId) => (
                      <TableCell key={columnId}>
                        {columnId === 'reportTitle' ? (
                          <a href={row['reportURL']} target="_blank" rel="noopener noreferrer">
                            {row[columnId]}
                          </a>
                        ) : (
                          row[columnId]
                        )}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Button onClick={() => handleShowDetails(row)} variant='outlined'>
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 75, 100]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </StyledPaper>

      <Dialog open={isModalOpen} onClose={handleModalClose} maxWidth="lg">
        <DialogTitle>Lead Details</DialogTitle>
        <DialogContent>
          {selectedRow && (
            <>
              <DetailsContent rowData={selectedRow} visibleColumns={columnsToShowInDetails} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Close</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Dashboard;
