import React, { useState } from 'react';
import { format } from 'date-fns';
import ApiConfig from './ApiConfig';
import CommentForm from './SalesComments/CommentForm';
import CommentsTable from './SalesComments/CommentsTable';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon from MUI
import StatusUpdateForm from './EnquiryUpdate/StatusUpdateForm';

const DetailsContent = ({ title, rowData, onClose }) => {
    const [open, setOpen] = useState(true);
    const [comments, setComments] = useState(rowData[0].enquiryCommentsModel || []);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const highlightedStyle = {
        fontWeight: 'bold',
        color: 'blue', // You can adjust the color as needed
    };

    return (

        <DialogContent>

            <Grid container spacing={2}>
                {rowData && (
                    <>
                        {/* Full-width column for "reportTitle" */}
                        <Grid item xs={12}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>Report Title</Typography>
                                <Typography variant="body1" style={{ fontWeight: 'bold', backgroundColor: 'yellow' }}>{rowData[0].reportTitle}</Typography>
                            </Box>
                        </Grid>
                        {/* First three columns */}
                        <Grid item xs={3}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>Lead Date</Typography>
                                <Typography variant="body1">{rowData[0].leadDate}</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={3}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>Customer Name</Typography>
                                <Typography variant="body1">{rowData[0].customerName}</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={3}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>Email</Typography>
                                <Typography variant="body1">{rowData[0].email}</Typography>
                            </Box>
                        </Grid>

                        {/* Full-length columns */}
                        <Grid item xs={3}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>Phone</Typography>
                                <Typography variant="body1">{rowData[0].phone}</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={3}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>Company Name</Typography>
                                <Typography variant="body1">{rowData[0].nameOfCompany}</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={3}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>Designation</Typography>
                                <Typography variant="body1">{rowData[0].designation}</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={3}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>Country</Typography>
                                <Typography variant="body1">{rowData[0].country}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>Report ID</Typography>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>{rowData[0]['reportId']}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>IP Address</Typography>
                                <Typography variant="body1">{rowData[0]['ipAddress']}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>Description</Typography>
                                <Typography variant="body1">{rowData[0].description}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>Sample Available?</Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontSize: '16px', // larger font size
                                        color: rowData[0].isSampleReportAvailable ? '#000000' : '#FFFFFF', // black text color if YES, white text color if NO
                                        backgroundColor: rowData[0].isSampleReportAvailable ? '#00FF00' : '#FF0000', // green background for YES, red background for NO
                                        padding: '3px', // adding some padding
                                        borderRadius: '2px', // rounding the corners
                                        textAlign: 'center', // align center
                                        fontWeight: 'bold' // set to bold
                                    }}
                                >
                                    {rowData[0].isSampleReportAvailable ? 'YES' : 'NO'}
                                </Typography>  </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>Sample Report Path</Typography>
                                <Typography variant="body1" sx={{ fontSize: 15 }}>
                                    <a href={rowData[0].sampleReportPath} target="_blank" rel="noopener noreferrer">
                                        {rowData[0].sampleReportPath}
                                    </a></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>Lead ID</Typography>
                                <Typography
                                style={{
                                    fontWeight: 'bold',
                                    padding: '2',
                                    textAlign: 'center',
                                    backgroundColor: 'yellow'
                                }}
                                variant="body1" >{rowData[0]['id']}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 10 }}>Lead Status</Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        fontWeight: 'bold',
                                        padding: '2',
                                        textAlign: 'center',
                                        backgroundColor:
                                            rowData[0]['enquiryStatus'] === 'ACTIVE' || rowData[0]['enquiryStatus'] === 'CLOSED'
                                                ? '#00FF00'
                                                : 'red'
                                    }}
                                >
                                    {rowData[0]['enquiryStatus']}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={8}>
                            <StatusUpdateForm enquiryId={rowData[0].id} />
                        </Grid>

                        {/* Comments table */}
                        <CommentsTable comments={rowData[0].enquiryCommentsModel} />

                        {/* Comment form */}
                        <CommentForm enquiryId={rowData[0].id} />

                        {/* Full-width column for "reportURL" */}
                        <Grid item xs={12}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 9 }}>Report URL</Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
                                    <a href={rowData[0].reportURL} target="_blank" rel="noopener noreferrer">
                                        {rowData[0].reportURL}
                                    </a>
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Full-width column for "reportPurchaseURL" */}
                        <Grid item xs={12}>
                            <Box marginBottom={1}>
                                <Typography variant="subtitle1" sx={{ fontSize: 9 }}>Report Purchase URL</Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
                                    <a href={rowData[0].reportPurchaseURL} target="_blank" rel="noopener noreferrer">
                                        {rowData[0].reportPurchaseURL}
                                    </a>
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                )}

            </Grid>
        </DialogContent >
    );
};

export default DetailsContent;
