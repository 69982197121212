// src/apiConfig.js
const BASE_URL = 'https://api.absolutemarketresearch.com';

const ApiConfig = {
  login: `${BASE_URL}/api/User/CheckLoginMenuAccess`,
  enquiry: `${BASE_URL}/api/Enquiry`,
  enquiryAddComments: `${BASE_URL}/api/Enquiry/AddComments`,
  // Add more API endpoints as needed
};

export default ApiConfig;