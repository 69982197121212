// src/Login.js
import React, { useState } from 'react';
import { Button, TextField, Paper, Typography, Container } from '@mui/material';
import axios from 'axios';
import ApiConfig from './ApiConfig';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    try {
      // Make an API request to authenticate the user
      const response = await axios.get(`${ApiConfig.login}?Email=${encodeURIComponent(username)}&Password=${encodeURIComponent(password)}`);

      // Check if the response contains data
      if (response.data && response.data.length > 0) {
        const userData = response.data[0];

        // Set email and role in local storage
        localStorage.setItem('email', userData.email);
        localStorage.setItem('role', userData.role);

        // Check if the user has access to the dashboard
        if (userData.menuIsActive) {
          // Call the onLogin callback to set isLoggedIn to true
          if (typeof onLogin === 'function') {
            onLogin();
          }

          // Navigate to the dashboard
          navigate('/dashboard', { state: { user: userData } });
        } else {
          setError('User does not have access to the dashboard');
        }
      } else {
        setError('Invalid username or password');
      }
    } catch (error) {
      setError('Error during login. Please try again.');
      console.error('Login Error:', error);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
      }}
    >
      <Container component="main" maxWidth="xs" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center' }}>
        <Paper elevation={15} style={{ padding: 3, borderRadius: 8, boxShadow: 5, background: 'white', width: 400, padding: 20 }}>
          <Typography variant="h4" style={{ marginBottom: 2, color: '#3f51b5', fontWeight: 'bold' }}>
            <center>Absolute Market</center>
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Typography color="error" style={{ marginBottom: 2 }}>{error}</Typography>}
          <Button variant="contained" color="primary" fullWidth onClick={handleLogin} style={{ marginTop: 2, height: '56px', fontSize: '1.5rem' }}>
            Login
          </Button>
        </Paper>
      </Container>
    </div>
  );
};

export default Login;
