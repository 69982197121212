import React from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { format } from 'date-fns';
import { grey } from '@mui/material/colors';

const CommentsTable = ({ comments }) => {
    return (
        <Box marginTop={2} marginBottom={2} width={'100%'}>
            {comments && comments.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table style={{ borderCollapse: 'collapse' }}>
                        <TableHead style={{ backgroundColor: grey[200] }}>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Comments</TableCell>
                                <TableCell>Added By</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {comments.map((comment, index) => (
                                <TableRow key={index} style={{ '&:hover': { backgroundColor: grey[100] } }}>
                                    <TableCell style={{ border: '1px solid #ddd', padding: '8px' }}>
                                        {format(new Date(comment.commentDate), 'dd-MMM-yyyy HH:mm:ss')}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid #ddd', padding: '8px' }}>{comment.comments}</TableCell>
                                    <TableCell style={{ border: '1px solid #ddd', padding: '8px' }}>{comment.commentsAddedBy}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (

                <Typography>No comments available</Typography>

            )}
        </Box>
    );
};

export default CommentsTable;
