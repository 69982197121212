import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, MenuItem, Box  } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiConfig from '../ApiConfig';

const StatusUpdateForm = ({ enquiryId, onClose }) => {
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState(''); // State to store the API response
    const [isLoading, setIsLoading] = useState(false);

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
        setError(''); // Clear the error message when the user starts selecting a status
    };

    const handleSubmit = () => {
        if (!status) {
            setError('Status cannot be empty');
            return;
        }

        setIsLoading(true);

        const apiPayload = {
            enquiryStatus: status,
        };

        const apiUrl = `https://api.absolutemarketresearch.com/api/Enquiry?EnquiryId=${enquiryId}`;

        fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(apiPayload),
        })
        .then(response => response.text()) // Handle the response as plain text
        .then(data => {
            setResponseMessage(data); // Set the response message in the state
            toast.success(data, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            if (onClose) {
                onClose();
            }
        })
        .catch(error => {
            console.error('API Error:', error);
            setError('An error occurred while updating the enquiry status.');
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <Box sx={{ backgroundColor: 'whitesmoke', padding: 2, borderRadius: 1 }}>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    select
                    label="Lead Status"
                    value={status}
                    onChange={handleStatusChange}
                    error={!!error}
                    helperText={error}
                >
                    <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                    <MenuItem value="CLOSED">CLOSED</MenuItem>
                    <MenuItem value="INVALID">INVALID</MenuItem>
                </TextField>
            </Grid>

            <Grid item xs={6}>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    {isLoading ? 'Submitting Lead status...' : 'Update Lead Status'}
                </Button>
            </Grid>

            <ToastContainer />
        </Grid>
        </Box>
    );
};

export default StatusUpdateForm;
