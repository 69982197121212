import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiConfig from '../ApiConfig';

const CommentForm = ({ enquiryId, onSubmit, onClose }) => {
    const [comment, setComment] = useState('');
    const [error, setError] = useState('');
    const [commentsAddedBy, setCommentsAddedBy] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Retrieve email from local storage
        const email = localStorage.getItem('email');
        // You can also retrieve role if needed: const role = localStorage.getItem('role');

        // Update the commentsAddedBy state with the retrieved email
        setCommentsAddedBy(email || 'Email Not Found!'); // Fallback to 'Admin' if email is not available
    }, []); // Empty dependency array to run the effect only once on mount

    const handleCommentChange = (event) => {
        setComment(event.target.value);
        // Clear the error message when the user starts typing
        setError('');
    };

    const handleSubmit = () => {
        // Check if the comment is empty
        if (!comment.trim()) {
            setError('Comment cannot be empty');
            return;
        }

        // Set loading state to true
        setIsLoading(true);

        // Prepare payload for API call
        const apiPayload = {
            enquiryId: enquiryId,
            commentDate: new Date().toISOString(),
            comments: comment,
            commentsAddedBy: commentsAddedBy,
        };

        // API endpoint
        const apiUrl = ApiConfig.enquiryAddComments;

        // API call
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(apiPayload),
        })
           //.then(response => response.json())
            .then(data => {
                console.log('API Response:', data);
                // Pass the new comment data to the parent component
                //onSubmit(data);

                // Show toast message
                toast.success(data.message, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });

                setComment(''); // Clear the comment field
                // Close the popup if the onClose prop is provided
                if (onClose) {
                    onClose();
                }
            })
            .catch(error => {
                console.error('API Error:', error);
                setError('Error submitting comment. Please try again.'); // Set a more user-friendly error message
            })
            .finally(() => {
                // Set loading state to false, whether the request succeeds or fails
                setIsLoading(false);
            });
    };

    return (
        <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Comment"
                    multiline
                    rows={1}
                    value={comment}
                    onChange={handleCommentChange}
                    error={!!error}
                    helperText={error}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isLoading} // Disable the button when submitting
                >
                    {isLoading ? 'Submitting...' : 'Submit Comment'}
                </Button>
            </Grid>
            <ToastContainer />
        </Grid>
    );
};

export default CommentForm;
